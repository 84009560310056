import React from "react"
import Layout from "../../components/layout"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import * as styles from "./careers.module.scss"
import { Link } from "gatsby"

const Careers = ({ pageContext, location }) => {
  return (
    <Layout>
      <InnerHeader
        pageTitle="Careers"
        pageContext={pageContext}
        location={location}
      />
      <div className={`inner-y-padding ${styles.wrapper}`}>
        <div className="inner-container-alt">
          <div className={` ${styles.links}`}>
            <h2 className="h3">Find out more</h2>
            <ul>
              <li>
                <a
                  href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst="
                  className="biggerLink"
                  title="Careers"
                >
                  Careers Portal
                </a>
              </li>
              <li>
                <Link
                  to="./benefits-snapshot"
                  className="biggerLink"
                  title="Benefits Snapshot"
                >
                  Benefits Snapshot
                </Link>
              </li>
              <li>
                <Link to="./culture" className="biggerLink" title="Culture">
                  Culture
                </Link>
              </li>
              <li>
                <Link
                  to="./profile-roles-testimonials"
                  className="biggerLink"
                  title="Testimonials"
                >
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Careers
